import React from 'react'
import { Edit, TabbedForm, FormTab, TextInput, DateTimeInput, ReferenceField, TextField } from 'react-admin'

const Title = ({ record }) => (
  <span>Upload Page {record ? `${record.friendly_name || record.id}` : ''}</span>
)

export default (props) => (
  <Edit
    title={<Title />}
    {...props}
  >
    <TabbedForm>
      <FormTab label='Upload Page'>
        <TextInput source='id' disabled />
        <TextInput source='friendly_name' disabled />
        <DateTimeInput source='created' disabled />
        <ReferenceField source='team_id' reference='teams'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='owner_id' reference='beardusers'>
          <TextField source='display_name' />
        </ReferenceField>
        <TextInput source='enabled' disabled />
        <TextInput source='type' disabled />
        <TextInput source='expiry_days' disabled />
        <TextInput source='last_received' disabled />
      </FormTab>
      <FormTab label='Hard Limits'>
        <TextInput source='transfer_limit' />
        <TextInput source='transfer_used' disabled />
        <TextInput source='max_size_per_upload' />
        <TextInput source='max_files_per_upload' />
      </FormTab>
    </TabbedForm>
  </Edit>
)
