import React from 'react'
import { List, TextField, Datagrid, ReferenceField, Pagination, DateField, Filter, TextInput, ReferenceInput, AutocompleteInput, ShowButton } from 'react-admin'

const ListFilters = (props) => (
  <Filter {...props}>
    <TextInput label='Display name' source='display_name' />
    <TextInput label='Primary email' source='primary_email__value' />
    <ReferenceInput
      label='Team'
      source=':team_id'
      reference='teams'
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText='name' optionValue='id' />
    </ReferenceInput>
    <TextInput label='IP' source='ip' />
  </Filter>
)

const ListFields = () => (
  <Datagrid>
    <DateField source='created' showTime />
    <ReferenceField source='team_id' reference='teams' sortBy='team__name'>
      <TextField source='name' />
    </ReferenceField>
    <TextField source='display_name' />
    <TextField source='primary_email.value' label='Email' />
    <DateField source='last_heartbeat' showTime />
    <ShowButton />
  </Datagrid>
)

export default (props) => (
  <List
    bulkActionButtons={false}
    pagination={
      <Pagination rowsPerPageOptions={[10]} />
    }
    filters={<ListFilters />}
    {...props}
  >
    <ListFields />
  </List>
)

export { ListFields }
