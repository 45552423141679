/* global localStorage */

import Modal from '@material-ui/core/Modal'
import QRCode from 'qrcode.react'
import { Button, ResettableTextField } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/icons/Send'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import apiFetch from '../../reactAdmin/apiFetch'

const useStyles = makeStyles((theme) => (
  {
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '150px',
      left: '400px'
    }
  }
))

export default ({ modalOpen, handleCloseModal, sharedSecret }) => {
  const [otp, otpSet] = useState('')
  const [loadingOtp, loadingOtpSet] = useState(false)
  const [errored, errorredSet] = useState(false)
  const [recoverCodes, recoverCodesSet] = useState([])
  const classes = useStyles()
  const onChange = (setter) => (e) => (setter(e.target.value))
  const handleCloseModalWrapper = () => {
    otpSet('')
    loadingOtpSet(false)
    errorredSet(false)
    recoverCodesSet([])
    handleCloseModal()
  }
  const getOtpURI = () => {
    const url = new URL(`otpauth://totp/${encodeURI('Seald Admin')}:${encodeURI(localStorage.getItem('email'))}`)
    url.searchParams.append('secret', sharedSecret)
    url.searchParams.append('issuer', 'Seald Admin')
    return url.toString()
  }
  const validate = async () => {
    loadingOtpSet(true)
    try {
      const result = await apiFetch(
        '/adminapi/twofa/confirm/', 'POST', { code: otp }
      )
      const data = await result.json()
      recoverCodesSet(data.recover_codes)
      errorredSet(false)
    } catch (e) {
      errorredSet(true)
    }
    loadingOtpSet(false)
  }
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModalWrapper}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div className={classes.paper}>
        <h2 id='simple-modal-title'>Activate 2FA</h2>
        {recoverCodes.length === 0 && (
          <div>
            <p id='simple-modal-description'>
                Please scan the following QRCode with your authentication application and validate it using an OTP .
            </p>
            <QRCode value={getOtpURI()} size={256} />
            <div>
              {errored && 'Something went wrong, please retry'}
              <ResettableTextField disabled={loadingOtp} value={otp} onChange={onChange(otpSet)} label='OTP' />
            </div>
            <div>
              <Button disabled={loadingOtp} onClick={validate} label='Confirm'>
                {loadingOtp ? <CircularProgress size={20} /> : <Icon />}
              </Button>
            </div>
          </div>
        )}
        {recoverCodes.length > 0 && (
          <div>
            <p id='simple-modal-description'>
              Success, please save the following recovery code, you
              will need them in case you are unable to use your authenticator.
            </p>
            <ul>
              {recoverCodes.map((v, i) => (<li key={i}>{v}</li>))}
            </ul>
            <Button label='Ok' onClick={handleCloseModalWrapper} />
          </div>
        )}
      </div>
    </Modal>
  )
}
