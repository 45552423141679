import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Datagrid, showNotification, TextField } from 'react-admin'
import StripeIcon from '@material-ui/icons/CreditCard'
import ReportIcon from '@material-ui/icons/Speed'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import apiFetch from '../reactAdmin/apiFetch'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'

const fetchSendToStripe = async (reportId, showNotification) => {
  try {
    await apiFetch(`/adminapi/sdkmonthlyreport/${reportId}/send_to_stripe/`, 'POST')
    showNotification('Sent to stripe', 'success')
  } catch (e) {
    showNotification(e.toString(), 'error')
  }
}

const SSKSSMSReport = ({ record = {}, showNotification }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(false)
  const showReport = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (data) return
    if (!open) return
    (async () => {
      try {
        const year = record.month.split('-')[0]
        const month = record.month.split('-')[1]
        const result = await apiFetch(
          `/adminapi/teams/${record.team_id}/ssks_sms_monthly_report/?date=${year}-${month}`
        )
        setData(await result.json())
      } catch (error) {
        showNotification(error.message || 'Unknown error', 'error')
        setOpen(false)
      }
    })()
  }, [record, open, data, showNotification, setData, setOpen])
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Report</DialogTitle>
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell align='left'>last_updated</TableCell>
              <TableCell align='right'>{data ? `${data.last_updated}` : <CircularProgress size={20} />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>sms_quota</TableCell>
              <TableCell align='right'>{data ? `${data.sms_quota}` : <CircularProgress size={20} />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>sms_used</TableCell>
              <TableCell align='right'>{data ? `${data.sms_used}` : <CircularProgress size={20} />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>sms_centi_credit_used</TableCell>
              <TableCell align='right'>{data ? `${data.sms_centi_credit_used}` : <CircularProgress size={20} />}</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Dialog>
      <Button label='SSKS SMS Report' onClick={showReport}>
        <ReportIcon />
      </Button>
    </>
  )
}

SSKSSMSReport.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func
}

const ConnectedSSKSSMSReport = connect(null, {
  showNotification
})(SSKSSMSReport)

const Report = ({ record = {} }) => {
  const [open, setOpen] = useState(false)
  const showReport = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const hasSdkLicencesResults = Object.keys(record.sdk_licences_results).length > 0
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Report</DialogTitle>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>sent_to_stripe</TableCell>
                <TableCell align='right'>{`${record.sent_to_stripe}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>hosted_identities_keys</TableCell>
                <TableCell align='right'>
                  {`${hasSdkLicencesResults ? record.sdk_licences_results.identities.keys : -1}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>hosted_identities_groups_and_messages</TableCell>
                <TableCell align='right'>
                  {`${hasSdkLicencesResults ? record.sdk_licences_results.identities.groups_and_messages : -1}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>hosted_groups_keys</TableCell>
                <TableCell align='right'>
                  {`${hasSdkLicencesResults ? record.sdk_licences_results.groups.keys : -1}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>hosted_groups_messages</TableCell>
                <TableCell align='right'>
                  {`${hasSdkLicencesResults ? record.sdk_licences_results.groups.messages : -1}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>hosted_outsiders</TableCell>
                <TableCell align='right'>
                  {`${hasSdkLicencesResults ? record.sdk_licences_results.outsiders : -1}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>deleted_identities_keys</TableCell>
                <TableCell align='right'>{`${record.deleted_identities_keys}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>deleted_identities_groups_and_messages</TableCell>
                <TableCell align='right'>{`${record.deleted_identities_groups_and_messages}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>deleted_groups_keys</TableCell>
                <TableCell align='right'>{`${record.deleted_groups_keys}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>deleted_groups_messages</TableCell>
                <TableCell align='right'>{`${record.deleted_groups_messages}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>deleted_outsiders</TableCell>
                <TableCell align='right'>{`${record.deleted_outsiders}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
      <Button label='Report' onClick={showReport}>
        <ReportIcon />
      </Button>
    </>
  )
}

Report.propTypes = {
  record: PropTypes.object
}

const SendToStripe = ({ record = {}, showNotification }) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    try {
      await fetchSendToStripe(record.id, showNotification)
    } finally {
      setLoading(false)
    }
  }
  const d = new Date()
  const currentMonth = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-01`
  const disabled = (currentMonth !== record.month) || (record.total_sent_to_stripe > -1)
  return (
    loading
      ? <CircularProgress />
      : (
        <Button
          label='Send To Stripe'
          disabled={disabled}
          onClick={handleClick}
        >
          <StripeIcon />
        </Button>
      )
  )
}

SendToStripe.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func
}

const ConnectedSendToStripe = connect(null, {
  showNotification
})(SendToStripe)

const SDKMonthlyReport = () => {
  return (
    <Datagrid>
      <TextField source='month' />
      <TextField source='closed' />
      <TextField source='total_sent_to_stripe' />
      <ConnectedSSKSSMSReport />
      <Report />
      <ConnectedSendToStripe />
    </Datagrid>
  )
}

export default SDKMonthlyReport
