
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import TwoFA from '../../components/TwoFA/TwoFA'

const useStyles = makeStyles((theme) => (
  {
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '150px',
      left: '400px'
    }
  }
))

export default (props) => {
  const classes = useStyles()
  return (
    <Card>
      <Title title='Preferences' />
      <CardContent>
        <div className={classes.label}>
          Two-factor auth.
        </div>
        <TwoFA />
      </CardContent>
    </Card>
  )
}
