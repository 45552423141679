/* https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/auth/Login.tsx */

import React, {
  createElement,
  useRef,
  useEffect,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Card, Avatar } from '@material-ui/core'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import LockIcon from '@material-ui/icons/Lock'
import { useHistory } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { Notification, defaultTheme } from 'react-admin'

import DefaultLoginForm from './LoginForm'

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage:
                'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)'
    },
    card: {
      minWidth: 300,
      marginTop: '6em'
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    }
  }),
  { name: 'RaLogin' }
)

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login = props => {
  const {
    theme,
    notification,
    classes: classesOverride,
    className,
    children,
    ...rest
  } = props
  const containerRef = useRef()
  const classes = useStyles(props)
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme])
  const checkAuth = useCheckAuth()
  const history = useHistory()
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, history])

  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
      >
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          {children}
        </Card>
        {notification ? createElement(notification) : null}
      </div>
    </ThemeProvider>
  )
}

Login.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object
}

Login.defaultProps = {
  theme: defaultTheme,
  children: <DefaultLoginForm />,
  notification: Notification
}

export default Login
