import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Link
} from 'react-admin'
import Icon from '@material-ui/icons/DirectionsWalk'
import Icon2 from '@material-ui/icons/Search'
import apiFetch from '../reactAdmin/apiFetch'

const fetchImpersonate = async (dashboarduser, teamId) => {
  const response = await apiFetch(
    `/adminapi/dashboardusers/${dashboarduser}/impersonate/`,
    'POST',
    { team_id: teamId }
  )
  const data = await response.json()
  window.open(`https://${data.dashboard}/dashboardapi/v2/admin/impersonate/?token=${data.token}`, '_blank')
}

const Impersonate = ({ record = {}, dashboarduser = '' }) => (
  <div style={{display: 'block'}}>
    {record.name}
    <Button onClick={async () => fetchImpersonate(dashboarduser, record.id)} label="Imp.">
      <Icon/>
    </Button>
    <Button
      component={Link}
      to={{
        pathname: `/teams/${record.id}`
      }}
      label="Det."
    >
      <Icon2/>
    </Button>
  </div>
)

Impersonate.propTypes = {
  record: PropTypes.object,
  dashboarduser: PropTypes.string
}

export default Impersonate
