import {
  GET_LIST, CREATE, GET_ONE, UPDATE, GET_MANY, GET_MANY_REFERENCE, DELETE
} from 'react-admin'
import apiFetch, { createUrl } from './apiFetch'

const bypassedResources = ['preferences']

export default async (type, resource, params) => {
  if (bypassedResources.includes(resource)) {
    return { data: [], total: 0 }
  }
  let response
  let data
  let url
  let sendEmailParam
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      url = createUrl(`/adminapi/${resource}/`)
      url.searchParams.append(
        'ordering',
        `${params.sort.order === 'DESC' ? '-' : ''}${params.sort.field.replace('.', '__')}`)
      url.searchParams.append('page', params.pagination.page)
      if (type === GET_MANY_REFERENCE) url.searchParams.append(params.target, params.id)
      if (params.filter) {
        Object.keys(params.filter).forEach(key => {
          url.searchParams.append(key, params.filter[key])
        })
      }
      response = await apiFetch(url)
      data = await response.json()
      return { data: data.results, total: data.count }
    case CREATE:
      sendEmailParam = ''
      if ('send_email' in params.data) {
        sendEmailParam = `?send_email=${params.data.send_email}`
      }
      response = await apiFetch(`/adminapi/${resource}/${sendEmailParam}`, 'POST', params.data)
      data = await response.json()
      return { data }
    case GET_ONE:
      response = await apiFetch(`/adminapi/${resource}/${params.id}/`)
      data = await response.json()
      return { data }
    case GET_MANY:
      data = await Promise.all(params.ids.map(async (id) => (
        (await apiFetch(`/adminapi/${resource}/${id}/`)).json()
      )))
      return { data }
    case UPDATE:
      response = await apiFetch(`/adminapi/${resource}/${params.id}/`, 'PUT', params.data)
      data = await response.json()
      return { data }
    case DELETE:
      await apiFetch(`/adminapi/${resource}/${params.id}/`, 'DELETE')
      return { data: params.previousData }
    default:
      console.log(type, resource, params)
      throw new Error(`${type} n'est pas implémenté dans dataProvider`)
  }
}
