import React, { useState } from 'react'
import Icon from '@material-ui/icons/Send'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Button,
  useNotify,
  useRefresh,
  useCreate,
  ResettableTextField
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

const NewDomainValidationKey = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [domain, setDomain] = useState('')
  const [loading, setLoading] = useState(false)
  const [create] = useCreate('domainvalidationkeys')
  const notify = useNotify()
  const refresh = useRefresh()
  const handleClick = () => { setShowDialog(true) }
  const handleCloseClick = () => { setShowDialog(false) }
  const onClick = () => {
    setLoading(true)
    create(
      { payload: { data: { domain, team_id: record.id } } },
      {
        onSuccess: ({ data: newRecord }) => {
          setShowDialog(false)
          setLoading(false)
          refresh()
        },
        onFailure: (error) => {
          notify(error.message, 'error')
          setLoading(false)
        }
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label='Add domain'>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Add domain'
      >
        <DialogTitle>Add domain</DialogTitle>
        <DialogContent>
          <div>
            <ResettableTextField value={domain} onChange={e => { setDomain(e.target.value) }} label='Domain' />
          </div>
          <div>
            <Button disabled={loading} onClick={onClick} label='Add'>
              {loading ? <CircularProgress size={20} /> : <Icon />}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NewDomainValidationKey
