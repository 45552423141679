import React, { useEffect, useState, useCallback } from 'react'
import apiFetch from '../reactAdmin/apiFetch'
import { Button, ResettableTextField } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/icons/Send'

const fields = [
  'subscription_item',
  'sms_subscription_item',
  'billing_cycle_anchor',
  'billing_mode'
]

const BillingInfo = ({ record }) => {
  const [billingInfo, setBillingInfo] = useState({})
  const [loadings, setLoadings] = useState(false)
  const fetchOptions = useCallback(async () => {
    setLoadings(true)
    const response = await apiFetch(`/adminapi/teams/${record.id}/`, 'GET')
    const data = await response.json()
    setBillingInfo(data.billing_info)
    setLoadings(false)
  }, [record])

  useEffect(() => {
    fetchOptions()
  }, [fetchOptions])

  const onClick = async () => {
    setLoadings(true)
    const response = await apiFetch(`/adminapi/teams/${record.id}/`, 'PATCH', { billing_info: billingInfo })
    const data = await response.json()
    setBillingInfo(data.billing_info)
    setLoadings(false)
  }

  const onChange = (field) => (e) => {
    const newBillingInfo = { ...billingInfo }
    newBillingInfo[field] = e.target.value
    setBillingInfo(newBillingInfo)
  }

  const specificFields = Object.keys(billingInfo).filter(v => !(fields.includes(v)))

  return (
    <div>
      {
        fields.concat(specificFields).map((field) =>
          <div key={field}>
            <ResettableTextField
              value={billingInfo[field]}
              onChange={onChange(field)}
              label={field}
              disabled={loadings}
            />
          </div>
        )
      }
      <div>
        <Button disabled={loadings} onClick={onClick} label='Update'>
          {loadings ? <CircularProgress size={20} /> : <Icon />}
        </Button>
      </div>
    </div>
  )
}

export default BillingInfo
