import { Button, useNotify, useRefresh, Confirm } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/icons/RemoveCircleOutline'
import React, { useState } from 'react'
import apiFetch from '../reactAdmin/apiFetch'

const RevokeConnector = ({ record, bearduser }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const onClick = async () => {
    setLoading(true)
    try {
      const connectorId = record.id
      const response = await apiFetch(`/adminapi/trustedconnectors/${connectorId}/revoke/?bearduser_id=${bearduser}`, 'POST')
      if (response.ok) {
        notify('Success', 'success')
      } else {
        notify('Error', 'error')
      }
    } finally {
      setLoading(false)
      refresh()
    }
  }
  return (
    <>
      <Button disabled={loading || record.state !== 'VO'} onClick={() => { setConfirmOpen(true) }} label='Revoke'>
        {loading ? <CircularProgress size={20} /> : <Icon />}
      </Button>
      <Confirm
        isOpen={confirmOpen}
        loading={loading}
        title='Revoke conector'
        content='Are you sure you want to revoke this connector ?'
        onConfirm={onClick}
        onClose={() => { setConfirmOpen(false) }}
      />
    </>
  )
}

export default RevokeConnector
