import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ResettableTextField, Button, showNotification } from 'react-admin'
import Icon from '@material-ui/icons/Send'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'
import apiFetch from '../reactAdmin/apiFetch'

const ExportEncryptedMessageKeys = ({ record, showNotification }) => {
  const [successEmail, setSuccessEmail] = useState('')
  const [errorEmail, setErroreEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const changeSuccessEmail = (e) => (setSuccessEmail(e.target.value))
  const changeErrorEmail = (e) => (setErroreEmail(e.target.value))

  const onClick = async () => {
    if (loading) return
    try {
      setLoading(true)
      const response = await apiFetch(`/adminapi/beardusers/${record.id}/export_encrypted_message_keys/`, 'POST', {
        email_success: successEmail,
        email_error: errorEmail,
        language: 'en'
      })
      await response.json()
      showNotification('Success', 'success')
    } catch (e) {
      showNotification(e.toString(), 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='ra-field'>
      <Typography component='p'>
        {`Export all Encrypted Message Keys of ${record.display_name} in csv.gz format`}<br />
        {'In case of success, send it to the success email.'}<br />
        {'In case of faillure, send notification to the error email.'}
      </Typography>
      <div>
        <ResettableTextField value={successEmail} onChange={changeSuccessEmail} label='Success email' />
      </div>
      <div>
        <ResettableTextField value={errorEmail} onChange={changeErrorEmail} label='Error email' />
      </div>
      <div>
        <Button disabled={loading} onClick={onClick} label='Send'>
          {loading ? <CircularProgress size={20} /> : <Icon />}
        </Button>
      </div>
    </div>
  )
}

ExportEncryptedMessageKeys.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func
}

export default connect(null, {
  showNotification
})(ExportEncryptedMessageKeys)
