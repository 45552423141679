import CircularProgress from '@material-ui/core/CircularProgress'
import React, { useEffect, useState } from 'react'
import apiFetch from '../../reactAdmin/apiFetch'
import Switch from '@material-ui/core/Switch'
import SetModal from './SetModal'
import RemoveModal from './RemoveModal'
import ResetCodesModal from './ResetCodesModal'
import { Button } from 'react-admin'

const LOADING = 0
const VALIDATED = 1
const NOT_VALIDATED = 2

export default () => {
  const [status, setStatus] = useState(LOADING)
  const [modalSetOpen, modalSetOpenSet] = useState(false)
  const [sharedSecret, sharedSecretSet] = useState('')
  const [modalRemoveOpen, modalRemoveOpenSet] = useState(false)
  const [modalResetOpen, modalResetOpenSet] = useState(false)
  const loadStatus = async () => {
    try {
      const result = await apiFetch(
        '/adminapi/twofa/status/'
      )
      const data = await result.json()
      if (data.confirmed) {
        setStatus(VALIDATED)
      } else {
        setStatus(NOT_VALIDATED)
      }
    } catch (e) {
      setStatus(NOT_VALIDATED)
    }
  }
  useEffect(() => {
    loadStatus()
  }, [setStatus])
  const handleCloseSetModal = () => {
    modalSetOpenSet(false)
    loadStatus()
  }
  const handleCloseRemoveModal = () => {
    modalRemoveOpenSet(false)
    loadStatus()
  }
  const handleCloseResetModal = () => {
    modalResetOpenSet(false)
    loadStatus()
  }
  const handleClick = async () => {
    if (status === NOT_VALIDATED) {
      setStatus(LOADING)
      const result = await apiFetch(
        '/adminapi/twofa/set/', 'POST'
      )
      const data = await result.json()
      sharedSecretSet(data.shared_secret)
      modalSetOpenSet(true)
    }
    if (status === VALIDATED) {
      modalRemoveOpenSet(true)
    }
  }
  const switchButton = <Switch color='primary' checked={status === VALIDATED} onChange={handleClick} />
  const loadingWidget = <CircularProgress size={20} />
  return (
    <>
      {(status !== LOADING) ? switchButton : loadingWidget}
      {status === VALIDATED &&
        <Button onClick={() => { modalResetOpenSet(true) }} label='Reset recovery codes' />}
      <SetModal modalOpen={modalSetOpen} handleCloseModal={handleCloseSetModal} sharedSecret={sharedSecret} />
      <RemoveModal modalOpen={modalRemoveOpen} handleCloseModal={handleCloseRemoveModal} />
      <ResetCodesModal modalOpen={modalResetOpen} handleCloseModal={handleCloseResetModal} />
    </>
  )
}
