import React from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceField, TextField
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'

const BeardUserTeam = ({ record = {}, ...rest }) => (
  <>
    <div className='ra-field'>
      <FormControl component='div' className='MuiFormControl-root MuiFormControl-marginDense'>
        <FormLabel component='label' className='MuiInputLabel-shrink'>User Type</FormLabel>
        <Typography
          component='span'
          variant='body2'
        >
          {record.team_id ? 'Beard User' : ''}{record.group_team_id ? 'Group' : ''}
        </Typography>
      </FormControl>
    </div>
    <div className='ra-field'>
      <FormControl component='div' className='MuiFormControl-root MuiFormControl-marginDense'>
        <FormLabel component='label' className='MuiInputLabel-shrink'>Team</FormLabel>
        <ReferenceField source='team_id' reference='teams' sortBy='team__name' record={record} {...rest}>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='group_team_id' reference='teams' sortBy='team__name' record={record} {...rest}>
          <TextField source='name' />
        </ReferenceField>
      </FormControl>
    </div>
  </>
)

BeardUserTeam.propTypes = {
  record: PropTypes.object
}

export default BeardUserTeam
