import React from 'react'
import { List, TextField, Datagrid, BooleanField, EditButton, Pagination, DateField, Filter, TextInput } from 'react-admin'

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Team' source='name' />
  </Filter>
)

export default (props) => (
  <List
    bulkActionButtons={false}
    pagination={
      <Pagination rowsPerPageOptions={[10]} />
    }
    filters={<PostFilter />}
    {...props}
  >
    <Datagrid>
      <DateField source='created' showTime />
      <TextField source='name' />
      <TextField source='nb_licences' label='Licences' sortable={false} />
      <BooleanField source='active' sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
)
