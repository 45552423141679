import React, { useState } from 'react'
import Icon from '@material-ui/icons/Cached'
import { connect } from 'react-redux'
import { Button, showNotification } from 'react-admin'
import apiFetch from '../reactAdmin/apiFetch'

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const fetchGetSdkLicenses = async (teamId) => {
  const response = await apiFetch(`/adminapi/teams/${teamId}/get_sdk_licences/`, 'POST')
  const data = await response.json()
  return data
}

const UpdateLicenseCount = ({ record, showNotification }) => {
  const [loading, setLoading] = useState(false)
  const update = async () => {
    setLoading(true)
    let updated = false
    while (!updated) {
      const data = await fetchGetSdkLicenses(record.id)
      if (data.sdk_licences === null) {
        updated = true
        showNotification('Something went wrong (check SDK option)', 'error')
      } else if (data.sdk_licences.status === 'ok') {
        updated = true
        showNotification('License count updated', 'success')
      } else {
        await sleep(2000)
      }
    }
    setLoading(false)
  }
  return (
    <Button
      label='Update License Count'
      disabled={loading}
      onClick={update}
    >
      <Icon />
    </Button>
  )
}

export default connect(null, {
  showNotification
})(UpdateLicenseCount)
