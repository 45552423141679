import Modal from '@material-ui/core/Modal'
import { Button, ResettableTextField } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/icons/Send'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import apiFetch from '../../reactAdmin/apiFetch'

const useStyles = makeStyles((theme) => (
  {
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '150px',
      left: '400px'
    }
  }
))

export default ({ modalOpen, handleCloseModal }) => {
  const [otp, otpSet] = useState('')
  const [loading, loadingSet] = useState(false)
  const [errored, errorredSet] = useState(false)
  const classes = useStyles()
  const onChange = (setter) => (e) => (setter(e.target.value))
  const handleCloseModalWrapper = () => {
    otpSet('')
    loadingSet(false)
    errorredSet(false)
    handleCloseModal()
  }
  const validate = async () => {
    loadingSet(true)
    try {
      const result = await apiFetch(
        '/adminapi/twofa/remove/', 'POST', { code: otp }
      )
      await result.json()
      errorredSet(false)
      handleCloseModalWrapper()
    } catch (e) {
      errorredSet(true)
    }
    loadingSet(false)
  }
  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModalWrapper}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div className={classes.paper}>
        <h2 id='simple-modal-title'>Disable 2FA</h2>
        <div>
          <p id='simple-modal-description'>
              Confirm 2FA removal with an OTP :
          </p>
          <div>
            {errored && 'Something went wrong, please retry'}
            <ResettableTextField disabled={loading} value={otp} onChange={onChange(otpSet)} label='OTP' />
          </div>
          <div>
            <Button disabled={loading} onClick={validate} label='Confirm'>
              {loading ? <CircularProgress size={20} /> : <Icon />}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
