import React from 'react'
import Icon from '@material-ui/icons/GroupAdd'
import { Button, Link } from 'react-admin'

export default ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/dashboardusers/create',
      search: `?source=${JSON.stringify({ team_id: record.id })}`
    }}
    label='Create dashboard user'
  >
    <Icon />
  </Button>
)
