import React, { useState } from 'react'
import Icon from '@material-ui/icons/Send'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Button,
  useNotify,
  ResettableTextField
} from 'react-admin'
import IconContentSMS from '@material-ui/icons/Sms'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import apiFetch from '../reactAdmin/apiFetch'

const SsksSMS = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [value, setValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingFetchQuota, setLoadingFetchQuota] = useState(false)
  const notify = useNotify()
  const handleClick = async () => {
    setShowDialog(true)
    setLoadingFetchQuota(true)
    try {
      const response = await apiFetch(`/adminapi/teams/${record.id}/sms_quota/`, 'GET')
      if (response.ok) {
        const data = await response.json()
        setValue(data.default_sms_quota)
        notify('Success', 'success')
      } else {
        notify('Error', 'error')
        closeQuotaModal()
      }
    } catch (error) {
      notify(error.message, 'error')
      closeQuotaModal()
    } finally {
      setLoadingFetchQuota(false)
    }
  }
  const closeQuotaModal = () => { setShowDialog(false) }
  const onClick = async () => {
    setLoading(true)
    try {
      const response = await apiFetch(`/adminapi/teams/${record.id}/sms_quota/`, 'POST', {
        new_sms_quota: value,
        force: true
      })
      if (response.ok) {
        notify('Success', 'success')
        closeQuotaModal()
      } else {
        notify('Error', 'error')
      }
    } catch (error) {
      notify(error.message, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button onClick={handleClick} label='Manage SSKS SMS quota'>
        <IconContentSMS />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={closeQuotaModal}
        aria-label='Manage SSKS SMS quota'
      >
        <DialogTitle>Manage SSKS SMS quota</DialogTitle>
        <DialogContent>
          <div>
            {
              (loadingFetchQuota || value === false)
                ? <CircularProgress size={20} />
                : <ResettableTextField value={value} onChange={e => { setValue(e.target.value) }} label='Quota' />
            }
          </div>
          <div>
            <Button disabled={loading} onClick={onClick} label='Update'>
              {(loading || loadingFetchQuota) ? <CircularProgress size={20} /> : <Icon />}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SsksSMS
