import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Pagination,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
  Toolbar,
  SaveButton
} from 'react-admin'
import NewDashboardUser from '../../components/NewDashboardUser'
import Impersonate from '../../components/Impersonate'
import NewDomainValidationKey from '../../components/NewDomainValidationKey'
import TeamPremiumOptions from '../../components/TeamPremiumOptions'
import ResetPassword from '../../components/ResetPassword'
import { ListFields as BeardUserListFields } from '../beardUsers/List'
import { ListFields as UploadPagesListFields } from '../uploadpages/List'
import BillingInfo from '../../components/BillingInfo'
import SDKMonthlyReport from '../../components/TeamSDKMonthlyReport'
import UpdateLicenseCount from '../../components/UpdateLicenseCount'
import SsksSMS from '../../components/SsksSMS'

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const Title = ({ record }) => {
  return <span>Team {record ? `${record.name}` : ''}</span>
}

export default (props) => (
  <Edit
    title={<Title />}
    {...props}
  >
    <TabbedForm toolbar={<PostEditToolbar />}>
      <FormTab label='Team'>
        <TextInput source='name' />
        <TextInput label='licences' source='nb_licences' />
        <TextInput source='sendapi_licenses' label='SendAPI Licences' />
        <TextInput source='sendapi_upload_expiry_days' label='Send API days retention' />
        <TextField label='Created by' source='created_by' />
        <BooleanInput source='active' />
        <SsksSMS />
      </FormTab>

      <FormTab label='Dashboard Users' path='dashboardusers'>
        <ReferenceManyField
          addLabel={false}
          reference='dashboardusers'
          target='team_id'
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}
        >
          <Datagrid>
            <TextField source='user.email' label='Email' />
            <TextField source='created' />
            <TextField source='forgot_pwd_date' sortable={false} />
            <Impersonate />
            <ResetPassword />
            <DeleteButton redirect={false} undoable={false} />
          </Datagrid>
        </ReferenceManyField>
        <NewDashboardUser />
      </FormTab>

      <FormTab label='Seald Users' path='beardusers'>
        <ReferenceManyField
          addLabel={false}
          reference='beardusers'
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}
          target=':team_id'
        >
          <BeardUserListFields />
        </ReferenceManyField>
      </FormTab>

      <FormTab label='Upload Pages' path='uploadpages'>
        <ReferenceManyField
          addLabel={false}
          reference='uploadpages'
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}
          target=':team_id'
        >
          <UploadPagesListFields />
        </ReferenceManyField>
      </FormTab>

      <FormTab label='Domain Validation Keys' path='domainvalidationkeys'>
        <ReferenceManyField
          addLabel={false}
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}
          reference='domainvalidationkeys'
          target='team_id'
        >
          <Datagrid>
            <TextField source='id' />
            <TextField source='domain' />
            <TextField source='key' />
            <DeleteButton redirect={false} undoable={false} />
          </Datagrid>
        </ReferenceManyField>
        <NewDomainValidationKey />
      </FormTab>

      <FormTab label='Options' path='premium_options'>
        <TeamPremiumOptions />
      </FormTab>

      <FormTab label='Billing info' path='billing'>
        <BillingInfo />
      </FormTab>

      <FormTab label='SDK Monthly report' path='sdk_monthly_report'>
        <UpdateLicenseCount />
        <ReferenceManyField
          addLabel={false}
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}
          reference='sdkmonthlyreport'
          target='team_id'
        >
          <SDKMonthlyReport />
        </ReferenceManyField>
      </FormTab>

    </TabbedForm>
  </Edit>
)
