/* global fetch */

class APIError extends Error {
  constructor (response) {
    super()
    this.message = response.statusText
    this.status = response.status
  }
}

const getCookie = (name) => {
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (const cookie of cookies) {
      if (cookie.trim().substring(0, name.length + 1) === (name + '=')) {
        return decodeURIComponent(cookie.trim().substring(name.length + 1))
      }
    }
  }
}

export const createUrl = (url) => {
  return new URL(url, window.location.protocol + window.location.hostname + ':' + window.location.port)
}

export default async (url, method = 'GET', data = {}) => {
  const csrftoken = getCookie('csrftoken')
  const response = await fetch(
    url,
    {
      method: method,
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrftoken },
      body: method === 'GET' ? undefined : JSON.stringify(data)
    }
  )
  if (!response.ok) {
    throw new APIError(response)
  }
  return response
}
