import React from 'react'
import {
  List,
  TextField,
  Datagrid,
  BooleanField,
  EditButton,
  Pagination,
  Filter,
  TextInput,
  DateField,
  ReferenceField
} from 'react-admin'

const ListFilters = (props) => (
  <Filter {...props}>
    <TextInput label='Team' source='team__name' />
    <TextInput label='Friendly name' source='friendly_name' />
  </Filter>
)

const ListFields = () => (
  <Datagrid>
    <DateField source='created' />
    <TextField source='id' sortable={false} />
    <TextField source='friendly_name' sortable={false} />
    <ReferenceField source='team_id' reference='teams'>
      <TextField source='name' />
    </ReferenceField>
    <ReferenceField source='owner_id' reference='beardusers'>
      <TextField source='display_name' />
    </ReferenceField>
    <BooleanField source='enabled' sortable={false} />
    <EditButton />
  </Datagrid>
)

export default (props) => (
  <List
    bulkActionButtons={false}
    pagination={
      <Pagination rowsPerPageOptions={[10]} />
    }
    filters={<ListFilters />}
    {...props}
  >
    <ListFields />
  </List>
)

export { ListFields }
