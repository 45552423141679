import React from 'react'
import { List, SingleFieldList, TextField, Datagrid, ReferenceArrayField, Pagination, DeleteWithConfirmButton, Filter, TextInput } from 'react-admin'
import Impersonate from '../../components/Impersonate'
import TwoFADashboardUser from '../../components/TwoFADashboardUser'
import ResetPassword from '../../components/ResetPassword'

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Email' source='user__email' />
  </Filter>
)

const SubReferenceArrayField = ({ ...props }) => (
  <ReferenceArrayField {...props}>
    <SingleFieldList linkType={false} style={{display: 'block'}}>
      <Impersonate dashboarduser={props.record.id} />
    </SingleFieldList>
  </ReferenceArrayField>
)

export default (props) => (
  <List
    bulkActionButtons={false}
    pagination={<Pagination rowsPerPageOptions={[10]} />}
    filters={<PostFilter />}
    {...props}
  >
    <Datagrid>
      <TextField source='user.email' label='Email' />
      <SubReferenceArrayField
        reference='teams'
        source='teams_id'
      />
      <TextField source='created' />
      <TwoFADashboardUser />
      <ResetPassword />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)
