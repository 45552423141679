import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, showNotification } from 'react-admin'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/icons/Security'
import apiFetch from '../reactAdmin/apiFetch'

const fetchResetPassword = async (dashboarduser, showNotification, sendEmail) => {
  try {
    const response = await apiFetch(`/adminapi/dashboardusers/${dashboarduser}/reset_password/?send_email=${sendEmail}`, 'POST')
    const data = await response.json()
    return data
  } catch (e) {
    showNotification(e, 'error')
  }
}

const ResetPassword = ({ record = {}, showNotification }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const setPassword = async () => {
    const data = await fetchResetPassword(record.id, showNotification, false)
    window.open(`${data.url}`, '_blank')
  }
  const getPasswordChangingURL = async () => {
    const data = await fetchResetPassword(record.id, showNotification, false)
    await navigator.clipboard.writeText(data.url)
    showNotification('Password chaning URL has been set in the clipboard', 'success')
  }
  const sendResetPasswordEmail = async () => {
    await fetchResetPassword(record.id, showNotification, true)
    showNotification('Email sent', 'success')
  }
  return (
    <>
      <Button label='Reset Password' onClick={handleClick}>
        <Icon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={setPassword}>Set password</MenuItem>
        <MenuItem onClick={getPasswordChangingURL}>Get password changing URL</MenuItem>
        <MenuItem onClick={sendResetPasswordEmail}>Send reset password email</MenuItem>
      </Menu>
    </>
  )
}

ResetPassword.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func
}

export default connect(null, {
  showNotification
})(ResetPassword)
