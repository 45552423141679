import React from 'react'
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, BooleanInput, required } from 'react-admin'

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='user.email' label='Email' validate={required()} />
      <ReferenceInput
        label='Team'
        source='team_id'
        reference='teams'
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText='name' optionValue='id' allowEmpty />
      </ReferenceInput>
      <BooleanInput source='send_email' label='Send an email' validate={required()} defaultValue />
    </SimpleForm>
  </Create>
)
