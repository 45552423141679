import React from 'react'
import { Create, SimpleForm, TextInput, required, BooleanInput } from 'react-admin'

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
      <TextInput source='nb_licences' label='Licences' validate={required()} defaultValue={10} />
      <TextInput source='sendapi_licenses' label='SendAPI Licences' validate={required()} defaultValue={0} />
      <TextInput source='sendapi_upload_expiry_days' label='Send API days retention' validate={required()} defaultValue={30} />
      <BooleanInput source='active' defaultValue style={{ visibility: 'hidden' }} />
    </SimpleForm>
  </Create>
)
