import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import authProvider from './reactAdmin/authProvider'
import dataProvider from './reactAdmin/dataProvider'
import { TeamsCreate, TeamsList, TeamsEdit } from './resourceHelpers/teams'
import { DashboardUsersCreate, DashboardUsersList } from './resourceHelpers/dashboardUsers'
import TeamsIcon from '@material-ui/icons/Domain'
import DashboardUsersIcon from '@material-ui/icons/AccountCircle'
import BeardUsersIcon from '@material-ui/icons/Group'
import { BeardUsersList, BeardUsersShow } from './resourceHelpers/beardUsers'
import { UploadPagesList, UploadPagesEdit } from './resourceHelpers/uploadpages'
import { PreferencesList } from './resourceHelpers/preferences'
import LoginPage from './components/Login/LoginPage'

class App extends Component {
  render () {
    return (
      <Admin loginPage={LoginPage} authProvider={authProvider} dataProvider={dataProvider}>
        <Resource icon={TeamsIcon} name='teams' label='Teams' list={TeamsList} create={TeamsCreate} edit={TeamsEdit} />
        <Resource icon={DashboardUsersIcon} name='dashboardusers' options={{ label: 'Dashboard Users' }} list={DashboardUsersList} create={DashboardUsersCreate} />
        <Resource icon={BeardUsersIcon} name='beardusers' options={{ label: 'Seald Users' }} list={BeardUsersList} show={BeardUsersShow} />
        <Resource icon={BeardUsersIcon} name='uploadpages' options={{ label: 'Upload Pages' }} list={UploadPagesList} edit={UploadPagesEdit} />
        <Resource name='trustedconnectors' />
        <Resource name='domainvalidationkeys' />
        <Resource name='sdkmonthlyreport' />
        <Resource name='preferences' list={PreferencesList} />
      </Admin>
    )
  }
}

export default App
