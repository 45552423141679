import React, { useState } from 'react'
import { Button, Confirm, useNotify, useRefresh } from 'react-admin'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/icons/RemoveCircleOutline'
import apiFetch from '../reactAdmin/apiFetch'

const FullRevokeUser = ({ record }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const onClick = async () => {
    setLoading(true)
    try {
      const bearduserId = record.id
      const response = await apiFetch(`/adminapi/beardusers/${bearduserId}/full_revoke/`, 'POST')
      if (response.ok) {
        notify('Success', 'success')
      } else {
        notify('Error', 'error')
      }
    } finally {
      setLoading(false)
      refresh()
    }
  }
  return (
    <>
      <Button disabled={loading || record.team_disabled} onClick={() => { setConfirmOpen(true) }} label='Full Revoke User'>
        {loading ? <CircularProgress size={20} /> : <Icon />}
      </Button>
      <Confirm
        isOpen={confirmOpen}
        loading={loading}
        title='Revoke conector'
        content='Are you sure you want to revoke this user (revoke all connector / device / groupe, remove from team) ?'
        onConfirm={onClick}
        onClose={() => { setConfirmOpen(false) }}
      />
    </>
  )
}

export default FullRevokeUser
