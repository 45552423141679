import React, { useEffect, useState, useCallback } from 'react'
import apiFetch from '../reactAdmin/apiFetch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

const TeamPremiumOptions = ({ record }) => {
  const [options, setOptions] = useState({ premium_options: [], premium_options_available: [] })
  const [loadings, setLoadings] = useState([])
  const fetchOptions = useCallback(async () => {
    const response = await apiFetch(`/adminapi/teams/${record.id}/premium_options/`, 'GET')
    const data = await response.json()
    setOptions(data)
  }, [record])

  useEffect(() => {
    fetchOptions()
  }, [fetchOptions])

  const onClick = (option, value) =>
    async () => {
      setLoadings([...loadings, option])
      const response = await apiFetch(`/adminapi/teams/${record.id}/premium_options/`, value ? 'POST' : 'DELETE', { option })
      const data = await response.json()
      setOptions(data)
      setLoadings(loadings.filter((o) => (o !== option)))
    }

  return (
    <div>
      <Table>
        <TableBody>
          {
            options.premium_options_available.map((optionAvailable) => {
              const isChecked = options.premium_options.includes(optionAvailable.option)
              const isLoading = loadings.includes(optionAvailable.option)
              return (
                <TableRow key={optionAvailable.option}>
                  <TableCell>
                    {optionAvailable.name}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={isChecked}
                      disabled={isLoading}
                      onChange={onClick(optionAvailable.option, !isChecked)}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}

export default TeamPremiumOptions
