import React from 'react'
import PropTypes from 'prop-types'
import {
  Edit, TabbedShowLayout,
  Tab, TextField, DateField, BooleanField, ReferenceManyField, Datagrid, FunctionField, Pagination
} from 'react-admin'
import ExportEncryptedMessageKeys from '../../components/ExportEncryptedMessageKeys'
import FullRevokeUser from '../../components/FullRevokeUser'
import RevokeConnector from '../../components/RevokeConnector'
import BeardUserTeam from '../../components/BeardUserTeam'

const Title = ({ record }) => {
  return <span>Seald User {record ? `${record.display_name}` : ''}</span>
}

Title.propTypes = {
  record: PropTypes.object
}

const getChallenge = (uuid) => (uuid ? `${uuid.substring(0, 3)}-${uuid.substring(3, 6)}` : 'N/A')

export default (props) => (
  <Edit
    title={<Title />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label='Seald User'>
        <TextField source='id' />
        <DateField source='created' showTime />
        <TextField source='display_name' />
        <BeardUserTeam />
        <TextField source='primary_email.value' />
        <DateField source='last_heartbeat' showTime />
        <FullRevokeUser />
      </Tab>
      <Tab label='Last seen' path='lastseen'>
        <BooleanField source='last_seen.success' />
        <TextField source='last_seen.ip' />
        <DateField source='last_seen.datetime' showTime />
        <TextField source='last_seen.location' />
        <TextField source='last_seen.user_agent' />
      </Tab>
      <Tab label='Trusted connectors' path='trustedconnectors'>
        <ReferenceManyField
          addLabel={false}
          reference='trustedconnectors'
          target='bearduser_id'
          perPage={10}
          pagination={<Pagination rowsPerPageOptions={[10]} />}

        >
          <Datagrid>
            <DateField source='created' showTime sortable={false} />
            <DateField source='updated' showTime sortable={false} />
            <TextField source='type' sortable={false} />
            <TextField source='state' sortable={false} />
            <TextField source='value' sortable={false} />
            <FunctionField
              label='Challenge'
              render={record => getChallenge(record.challenge)}
              sortable={false}
            />
            <RevokeConnector bearduser={props.id} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label='Export message keys' path='exportmessagekeys'>
        <ExportEncryptedMessageKeys />
      </Tab>
    </TabbedShowLayout>
  </Edit>
)
