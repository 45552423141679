/* global localStorage */

import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, AUTH_GET_PERMISSIONS, AUTH_ERROR } from 'react-admin'
import apiFetch from './apiFetch'

export default async (type, params) => {
  switch (type) {
    case AUTH_CHECK:
      await apiFetch('/adminapi/admin/is_logged/')
      return
    case AUTH_LOGIN:
      await apiFetch(
        '/adminapi/admin/login/',
        'POST',
        { email: params.username, password: params.password, otp: params.otp }
      )
      localStorage.setItem('email', params.username)
      return
    case AUTH_GET_PERMISSIONS:
      return
    case AUTH_LOGOUT:
      try {
        await apiFetch('/adminapi/admin/logout/', 'POST')
      } catch (e) {}
      return
    case AUTH_ERROR:
      return
    default:
      throw new Error(`Le type ${type} n'est pas traité`)
  }
}
