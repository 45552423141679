import React, { useState } from 'react'
import { Button } from 'react-admin'
import Icon from '@material-ui/icons/VpnLock'
import IconDel from '@material-ui/icons/HighlightOff'
import IconRef from '@material-ui/icons/Cached'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import apiFetch from '../reactAdmin/apiFetch'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

const UNSET = 0
const UNCONFIRMED = 1
const CONFIRMED = 2
const LOADING = 3
const ERROR = -1
const UNKNOWN = -2

const fetchTwoFA = async (dashboarduser, setState) => {
  setState(LOADING)
  try {
    const response = await apiFetch(`/adminapi/dashboardusers/${dashboarduser}/twofa/`, 'GET')
    if (response.ok) {
      const data = await response.json()
      setState(data.twofa_confirmed ? CONFIRMED : UNCONFIRMED)
    } else {
      setState(response.status === 404 ? UNSET : ERROR)
    }
  } catch (e) { setState(e.status === 404 ? UNSET : ERROR) }
}

const deleteTwoFA = async (dashboarduser, setState) => {
  setState(LOADING)
  try {
    await apiFetch(`/adminapi/dashboardusers/${dashboarduser}/twofa/`, 'DELETE')
    await fetchTwoFA(dashboarduser, setState)
  } catch (e) {
    setState(ERROR)
  }
}

const TwoFADashboardUser = ({ record = {} }) => {
  const [state, setState] = useState(UNKNOWN)
  const [dialogOpen, setDialogOpen] = useState(false)
  switch (state) {
    case UNSET:
      return (
        <div>
          2FA is not set
          <Button onClick={async () => fetchTwoFA(record.id, setState)}>
            <IconRef />
          </Button>
        </div>
      )
    case UNCONFIRMED:
    case CONFIRMED:
      return (
        <div>
          {state === CONFIRMED ? '2FA is set' : '2FA is not confirmed'}
          <Button onClick={async () => fetchTwoFA(record.id, setState)}>
            <IconRef />
          </Button>
          <Button onClick={() => { setDialogOpen(true) }}>
            <IconDel />
          </Button>
          <Dialog
            open={dialogOpen}
            onClose={() => { setDialogOpen(false) }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>Delete TWO FA</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Are you sure ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={async () => { setDialogOpen(false); await deleteTwoFA(record.id, setState) }}
                color='primary'
              >
                <>Delete</>
              </Button>
              <Button onClick={() => { setDialogOpen(false) }} color='primary' autoFocus>
                <>Cancel</>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    case LOADING:
      return <CircularProgress size={20} />
    case ERROR:
    case UNKNOWN:
    default:
      return (
        <Button
          onClick={async () => fetchTwoFA(record.id, setState)}
          label={state === UNKNOWN ? 'Check 2FA' : 'Error, retry ?'}
        >
          <Icon />
        </Button>
      )
  }
}

TwoFADashboardUser.propTypes = {
  record: PropTypes.object
}

export default TwoFADashboardUser
